import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { createTheme, ThemeProvider, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import UsersTable from '../../components/usersTable';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import MainLayout from '../../layouts/main';
import EmailPopoverButton from '../../components/email-popover-button';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import { createNotification } from '../../utils/alerts';
import AppBar from "../../layouts/chat/app-bar";

const UsersPage = () => {
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users/`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                    },
                });
                setUsers(response.data);
            } catch (error) {
                console.error(error);
                // Handle error appropriately
            }
        };

        fetchUsers();
    }, []);

    const handleDeleteUser = async (userId) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/users/${userId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            });
            setUsers(users.filter((user) => user.id !== userId));
            createNotification('success', 'User deleted successfully', isSmallScreen);
        } catch (error) {
            createNotification('error', 'Failed to delete user', isSmallScreen);
        }
    };

    const handleResendInvite = async (userId) => {
        try {
            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/${userId}/resend-invite`, {}, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            });
            createNotification('success', 'Invite resent successfully', isSmallScreen);
        } catch (error) {
            createNotification('error', 'Failed to resend invite', isSmallScreen);
        }
    };

    const handleSendInvite = async (email) => {
        try {
            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/invites/`, { email }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            });
            createNotification('success', 'Invite sent successfully', isSmallScreen);
            // Optionally, refresh the users list
        } catch (error) {
            createNotification('error', 'Failed to send invite', isSmallScreen);
        }
    };

    return (
        <Grid container direction="column" sx={{ height: '100vh' }} style={{ backgroundColor: '#f0f0f0' }}>
            <Grid
                item
                xs
                container
                justifyContent="center"
                direction="column"
                sx={{ padding: 2 }}
                style={{ maxWidth: '850px', alignSelf: 'center' }}
            >
                <AppBar />
                <Grid
                    container
                    xs
                    className="messages-section"
                    sx={{ overflowY: 'hidden', flexGrow: 1, alignContent: 'center', justifyContent: 'center' }}
                >

                    <Grid item xs={12} md={8} style={{ padding: isSmallScreen ? '10px' : '20px', maxWidth: '100%' }}>
                        <UsersTable
                            users={users}
                            onDeleteUser={handleDeleteUser}
                            onResendInvite={handleResendInvite}
                        />
                        <div
                            style={{
                                width: '100%',
                            }}
                        >
                            <EmailPopoverButton
                                onSubmit={handleSendInvite}
                                tooltip="Invite a new user"
                                readOnly={false}
                                type="button" // Change to 'button' type
                                buttonText="Add User" // Provide text for the button
                                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            />
                        </div>
                    </Grid>

                </Grid>

            </Grid>
        </Grid>
    );
};

export default UsersPage;
