import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { TextField, Button, Typography, Container } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import AppBar from "../../layouts/main/app-bar";
import MainLayout from '../../layouts/main';

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#585a5c',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: '#585a5c',
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#585a5c',
          '&.Mui-focused': {
            color: '#585a5c',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input::placeholder': {
            color: '#585a5c',
          },
          '&.Mui-focused .MuiInputBase-input::placeholder': {
            color: '#585a5c',
          },
        },
      },
    },
  },
});

const RegisterCompletePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [fullName, setFullName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('');
  const [inviteToken, setInviteToken] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const emailParam = params.get('email');
    if (!token || !emailParam) {
      navigate('/error');
    } else {
      setInviteToken(token);
      setEmail(emailParam);
    }
  }, [location, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/register/complete`, {
        full_name: fullName,
        password,
        confirm_password: confirmPassword,
        email,
        invite_token: inviteToken,
      });
      alert('Registration completed successfully!');
      navigate('/login');
    } catch (error) {
      console.error(error);
      alert('An error occurred during registration.');
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <MainLayout title={'Register'}>
        <AppBar />
        <Container maxWidth="sm">
          <Typography variant="h6">
            Complete Your Registration
          </Typography>
          <form
            onSubmit={handleSubmit}
            style={{ display: 'flex', flexDirection: 'column', gap: '15px', marginTop: '25px' }}
          >
            <TextField
              label="Name"
              fullWidth
              margin="none" // Remove the margin between fields
              variant="outlined"
              size="small"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              required
            />
            <TextField
              label="Password"
              type="password"
              fullWidth
              margin="none" // Remove the margin between fields
              variant="outlined"
              size="small"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <TextField
              label="Confirm Password"
              type="password"
              fullWidth
              margin="none" // Remove the margin between fields
              variant="outlined"
              size="small"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                marginTop: 2,
                backgroundColor: '#2e3239',
                '&:hover': { backgroundColor: '#585a5c' },
              }}
            >
              Complete Registration
            </Button>
          </form>
        </Container>
      </MainLayout>
    </ThemeProvider>
  );
};

export default RegisterCompletePage;
