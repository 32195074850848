import React, { useState, useRef } from 'react';
import { Popover, TextField, IconButton, Button, Tooltip } from '@mui/material';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import ReplyIcon from '@mui/icons-material/Reply';

function EmailPopoverButton({
  email: initialEmail = '',
  onSubmit,
  icon: IconComponent = MarkEmailUnreadIcon,
  tooltip = 'Send Invite',
  readOnly = false,
  type = 'icon', // Prop to determine button type: 'icon' or 'button'
  buttonText = 'Invite User', // Text for the button when type is 'button'
  anchorOrigin = { vertical: 'center', horizontal: 'left' }, // Prop for Popover anchorOrigin
  transformOrigin = { vertical: 'center', horizontal: 'right' }, // Prop for Popover transformOrigin
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [email, setEmail] = useState(initialEmail);
  const buttonRef = useRef(null); // Ref to store the button element

  // Handle button click to open the Popover
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Handle closing the Popover
  const handleClose = () => {
    setAnchorEl(null);
    setEmail(initialEmail); // Reset email on close
  };

  // Check if the Popover is open
  const open = Boolean(anchorEl);
  const id = open ? 'email-popover' : undefined;

  const handleEmailSubmit = () => {
    if (onSubmit) {
      onSubmit(email);
    }
    handleClose();
  };

  return (
    <div>
      {type === 'icon' ? (
        <Tooltip title={tooltip}>
          <IconButton sx={{ color: '#434851' }} onClick={handleClick} size="small">
            <IconComponent />
          </IconButton>
        </Tooltip>
      ) : (
        <Button
          variant="contained"
          onClick={handleClick}
          size="small"
          ref={buttonRef} // Attach the ref to the button
          sx={{
            margin: '8px 0',
            backgroundColor: '#434851', // Default color
            '&:hover': {
              backgroundColor: '#585a5c', // Hover color
            },
            '&:focus': {
              backgroundColor: '#585a5c', // Focus color
            },
            width: '100%', // Ensure the button width is 100%
          }}
        >
          {buttonText}
        </Button>
      )}

      {/* Popover that contains the email input field */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin} // Use the provided anchorOrigin prop
        transformOrigin={transformOrigin} // Use the provided transformOrigin prop
        PaperProps={{
          style: {
            borderRadius: '4px',
            width: buttonRef.current ? buttonRef.current.clientWidth : 'auto', // Match popover width to button width
            maxWidth: buttonRef.current ? buttonRef.current.clientWidth : 'auto', // Ensure the popover doesn't exceed button width
          },
        }}
      >
        <div style={{ padding: '8px 16px', display: 'flex', alignItems: 'center' }}>
          <TextField
            label="New User Email"
            variant="outlined"
            size="small"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ marginRight: '8px' }}
            InputProps={{
              readOnly: readOnly,
              style: {
                height: '30px',
                fontSize: '12px',
              },
            }}
            InputLabelProps={{
              style: {
                fontSize: '12px',
              },
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: '#434851',
                },
              },
              '& .MuiInputLabel-root': {
                '&.Mui-focused': {
                  color: '#434851',
                },
              },
            }}
          />
          <IconButton
            sx={{ color: '#434851' }}
            onClick={handleEmailSubmit}
            disabled={!email}
          >
            <ReplyIcon />
          </IconButton>
        </div>
      </Popover>
    </div>
  );
}

export default EmailPopoverButton;
