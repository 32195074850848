import React from "react";
import MuiAppBar from '@mui/material/AppBar';
import { Grid, useMediaQuery } from '@mui/material';
import './app-bar.scss';
import { useLocation } from "react-router-dom";
import AccountMenu from "../../../components/menu";
import Cisp1Logo from '../../../components/icons/cisp1-logo';

export default function AppBar() {
    const location = useLocation();  // Get the current location
    const isSmallScreen = useMediaQuery('(max-width:600px)');  // Define a breakpoint for small screens

    return (
        <MuiAppBar position="absolute" className="app-bar">
            <Grid 
                container 
                direction={isSmallScreen ? "column" : "row"} // Change direction based on screen size
                justifyContent="center" 
                alignItems="center" 
                gap={isSmallScreen ? 0 : 1} 
                padding={isSmallScreen ? 2.5 : 0} 
                className="app-bar__container"
            >
                <Grid item>
                    <Cisp1Logo className="cisp1-logo" style={{ width: isSmallScreen ? '120px' : '150px', marginTop: '5px' }} />
                </Grid>
                <Grid item style={{ marginTop: isSmallScreen ? '10px': '' }}>
                    <img 
                        src="vix-logo.jpg" 
                        alt="Logo" 
                        style={{ width: isSmallScreen ? '100px' : '110px' }} // Adjust size based on screen size
                    />
                </Grid>
                
                {/* Show AccountMenu only if the current path is '/' */}
                {location.pathname === '/' && (
                    <Grid item className="account-menu-container">
                        <AccountMenu />
                    </Grid>
                )}
            </Grid>
        </MuiAppBar>
    );
}
