import React, { useState, useRef } from 'react'
import Grid from '@mui/material/Grid'
import Textarea from '@mui/joy/Textarea';
import SendIcon from '@mui/icons-material/Send';
import IconButton from '@mui/material/IconButton'

import './question-bar.scss'

export default ({ context, setContext }) => {
    const [question, setQuestion] = useState('')
    const questionInputRef = useRef(null)
    const [sessionId,] = useState(Date.now().toString())

    const sendQuestion = async () => {
        const userMessage = { message_type: 'text', user_type: 'client', message: question, id: context.length };
        const newContext = [...context, userMessage];
    
        setContext(newContext);
        setQuestion('');
    
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/query/ask`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json', 
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`
                },
                body: JSON.stringify({ question, session_id: sessionId}),
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            const reader = response.body.getReader();
            const decoder = new TextDecoder('utf-8');
            let assistantMessage = { message_type: 'text', user_type: 'system', message: '', id: newContext.length };
    
            setContext((prevContext) => [...prevContext, assistantMessage]);
    
            while (true) {
                const { done, value } = await reader.read();
                if (done) break;
    
                const chunk = decoder.decode(value);
                assistantMessage.message += chunk;
    
                setContext((prevContext) => {
                    const updatedContext = [...prevContext];
                    updatedContext[updatedContext.length - 1] = assistantMessage;
                    return updatedContext;
                });
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && event.target.value) {
            event.preventDefault()
            sendQuestion()
        }
    }

    function SendButton() {
        return (
            <IconButton className="__button --blue-background" onClick={sendQuestion}>
                <SendIcon className="send-icon" />
            </IconButton>
        )
    }

    return (
        <Grid container item className="question-bar" gap={4} justifyContent="space-between" alignItems="center">
            <Textarea placeholder="Pergunte aqui" variant="plain" className="__input" value={question} onChange={({ target }) => setQuestion(target.value)} onKeyPress={handleKeyPress} slotProps={{ textarea: { ref: questionInputRef } }} />
            <SendButton />
        </Grid>
    )
}