import axiosInstance from "./apiConfig";

export const login = async (data) => {
    try {
      const params = new URLSearchParams();
      params.append('username', data.username);
      params.append('password', data.password);
  
      console.log(params);
      
      const response = await axiosInstance.post('/token', params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      return response.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
  };

export const refreshToken = async () => {
    try {
        const response = await axiosInstance.post('/refresh-token');
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const logout = async () => {
    try {
        const response = await axiosInstance.post('/logout');
        return response.data;
    } catch (error) {
        throw error;
    }
};

export default {}
