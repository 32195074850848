import './main.scss';
import AppBar from './app-bar';
import Content from './content';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function MainLayout({ children }) {
  return (
    <div className='main-layout'>
      <AppBar />
      <Content style={{ backgroundColor: '#f0f0f0' }}>
        {children}
        <ToastContainer />
      </Content>
    </div>
  );
}

export default MainLayout;
