import React from "react";
import './main-page.scss';
import { CssBaseline, Box, Container, Grid } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CardComponent from "../../components/menu-card";
import AppBar from "../../layouts/chat/app-bar";
import MainLayout from "../../layouts/main";


const theme = createTheme();

function MainPage() {
    return (
        <Grid container direction="column" sx={{ height: '100vh' }} style={{ backgroundColor: '#f0f0f0' }}>
            <Grid
                item
                xs
                container
                justifyContent="center"
                direction="column"
                sx={{ padding: 2 }}
                style={{ maxWidth: '850px', alignSelf: 'center' }}
            >
                <AppBar />
                <Grid
                    item
                    xs
                    className="messages-section"
                    sx={{ overflowY: 'hidden', flexGrow: 1, alignContent: 'center' }}
                >
                    <CssBaseline />
                    <Grid container spacing={2} justifyContent="center" alignItems="stretch">
                        {/* Wrap the last CardComponent with a specific class */}
                        <Grid item className="last-card">
                            <CardComponent title="Talk to your Data!" label="#GenAI" navigateTo="chats" />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        // <MainLayout title={'home'}>
        //     <div className="login-container">
        //         <Container component="main" maxWidth="lg">
        //             <CssBaseline />
        //             <Grid container spacing={2} justifyContent="center" alignItems="stretch">
        //                 {/* Wrap the last CardComponent with a specific class */}
        //                 <Grid item className="last-card">
        //                     <CardComponent title="Talk to your Data!" label="#GenAI" navigateTo="chats" />
        //                 </Grid>
        //             </Grid>
        //         </Container>
        //     </div>
        // </MainLayout>
    );
}

export default MainPage;
