import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme, useMediaQuery } from '@mui/material';
import EmailPopoverButton from '../email-popover-button';
import MailIcon from '@mui/icons-material/Mail';
import Tooltip from '@mui/material/Tooltip';
import { Chip } from '@mui/material';

const columns = [
  { id: 'name', label: 'Name', minWidth: 100 },
  { id: 'email', label: 'Email', minWidth: 150, align: 'left' },
  { id: 'status', label: 'Status', minWidth: 100, align: 'center' },
  { id: 'actions', label: 'Actions', minWidth: 100, align: 'center' },
];

export default function UsersTable({ users, onDeleteUser, onResendInvite }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: '100%', overflowX: 'auto', marginTop: 2 }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="users table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  sx={{ minWidth: column.minWidth, fontSize: isSmallScreen ? '0.8rem' : '1rem' }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((user) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={user.id} sx={{ height: isSmallScreen ? 30 : 50 }}>
                  {columns.map((column) => {
                    const value = (() => {
                      switch (column.id) {
                        case 'name':
                          return user.full_name || user.username;
                        case 'email':
                          return user.email;
                        case 'status':
                          return (
                            <Chip
                              label={user.is_active ? 'Active' : 'Invite Sent'}
                              sx={{
                                backgroundColor: user.is_active ? '#b4e4b7' : '#c2c2c2',
                                color: '#fff',
                              }}
                              size="small"
                            />
                          );
                        case 'actions':
                          if(user.email !== 'admin@cisp1.io'){
                            return (
                              <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <EmailPopoverButton
                                  email={user.email}
                                  onSubmit={() => onResendInvite(user.id)}
                                  icon={MailIcon}
                                  tooltip="Resend Invite"
                                  readOnly={true}
                                />
                                <Tooltip title="Delete User" arrow>
                                  <IconButton
                                    aria-label="delete"
                                    size="small"
                                    onClick={() => onDeleteUser(user.id)}
                                  >
                                    <DeleteIcon sx={{ color: '#434851' }} />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            );
                          }

                        default:
                          return user[column.id];
                      }
                    })();
                    return (
                      <TableCell key={column.id} align={column.align} sx={{ fontSize: isSmallScreen ? '0.8rem' : '1rem' }}>
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={users.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ fontSize: isSmallScreen ? '0.8rem' : '1rem' }}
      />
    </Paper>
  );
}
