import React from 'react';
import { useNavigate } from 'react-router-dom';
import './menu-card.scss';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const CardComponent = ({ title, label, navigateTo }) => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    if (navigateTo) {
      navigate(navigateTo);
    }
  };

  return (
    <Card
      className="custom-card"
      onClick={handleNavigation}
      sx={{
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '6px', 
        minWidth: 343,
        cursor: 'pointer',
        transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
        '&:hover': {
          transform: 'scale(1.05)',
          boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
        },
      }}
    >
      <CardContent className="card-content">
        <Chip label={label} className="card-chip" />
        <Typography variant="h5" className="card-title">
          {title}
        </Typography>
        <div className="card-arrow">
          <ArrowForwardIosIcon fontSize="small" />
        </div>
      </CardContent>
    </Card>
  );
};

export default CardComponent;
