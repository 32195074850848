import React, { useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './main-page.scss';
import { Grid, Button, Fab } from '@mui/material';
import AppBar from '../../layouts/chat/app-bar';
import QuestionBar from '../../components/question-bar';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const CodeBlock = ({ value, language }) => {
    const copyToClipboard = () => {
        navigator.clipboard.writeText(value);
    };

    return (
        <div style={{ position: 'relative' }}>
            <Button
                onClick={copyToClipboard}
                style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    backgroundColor: '#282c34',
                    color: '#ffffff',
                    padding: '5px 10px',
                    borderRadius: '5px',
                    cursor: 'pointer',
                }}
            >
                Copy
            </Button>
            <SyntaxHighlighter language={language || 'javascript'} style={okaidia}>
                {value}
            </SyntaxHighlighter>
        </div>
    );
};

export default function ChatPage() {
    const [context, setContext] = useState([
        {
            "user_type": "system",
            "message_type": "text",
            "message": "Estou aqui para ajudar você a consultar e analisar relatórios financeiros de forma rápida e precisa. Basta me dizer o que você está procurando, e eu encontrarei as informações mais relevantes para você. 🔍 ",
            "id": 0
        },
        {
            "user_type": "system",
            "message_type": "text",
            "message": "Se precisar de algo específico, é só perguntar! 🚀",
            "id": 1
        }
    ]);

    const [showScrollButton, setShowScrollButton] = useState(false);
    const [autoScrollEnabled, setAutoScrollEnabled] = useState(true); // Track autoscroll state
    const messagesSectionRef = useRef(null);

    const scrollToBottom = () => {
        if (messagesSectionRef.current) {
            messagesSectionRef.current.scrollTo({
                top: messagesSectionRef.current.scrollHeight,
                behavior: 'smooth'
            });
        }
    };

    const handleScroll = () => {
        if (messagesSectionRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = messagesSectionRef.current;

            const isAtBottom = scrollHeight - scrollTop <= clientHeight + 50;

            if (isAtBottom) {
                setAutoScrollEnabled(true); // Re-enable autoscroll when at the bottom
            } else {
                setAutoScrollEnabled(false); // Disable autoscroll on manual scroll up
            }

            setShowScrollButton(!isAtBottom); // Show scroll button if not at the bottom
        }
    };

    useEffect(() => {
        // Scroll to the bottom initially
        scrollToBottom();
    }, []);

    useEffect(() => {
        if (autoScrollEnabled) {
            // Scroll to the bottom whenever a new message is added
            scrollToBottom();
        }
    }, [context, autoScrollEnabled]);

    useEffect(() => {
        const messagesDiv = messagesSectionRef.current;
        if (messagesDiv) {
            messagesDiv.addEventListener('scroll', handleScroll);
            return () => messagesDiv.removeEventListener('scroll', handleScroll);
        }
    }, []);

    const ClientMessage = (id, message) => (
        <Grid container key={id} justifyContent="end">
            <Grid item style={{ alignSelf: 'flex-end', padding: '10px 20px', border: '2px solid #e9e9e9', borderRadius: '20px', backgroundColor: '#fff' }}>
                {message}
            </Grid>
        </Grid>
    );

    const SystemMessage = (id, message) => (
        <Grid key={id} style={{ alignSelf: 'flex-start', maxWidth: '100%' }}>
            <ReactMarkdown
                components={{
                    code({ node, inline, className, children, ...props }) {
                        const match = /language-(\w+)/.exec(className || '');
                        return !inline ? (
                            <CodeBlock value={String(children).replace(/\n$/, '')} language={match ? match[1] : ''} {...props} />
                        ) : (
                            <code className={className} {...props}>
                                {children}
                            </code>
                        );
                    }
                }}
            >
                {message}
            </ReactMarkdown>
        </Grid>
    );

    const renderTextMessage = (id, user_type, message) => (
        user_type === 'client' ? ClientMessage(id, message) : SystemMessage(id, message)
    );

    return (
        <Grid container className="chat-container" direction="column" sx={{ height: '100vh' }} style={{ backgroundColor: '#f0f0f0' }}>

            <Grid
                item
                xs
                container
                justifyContent="center"
                direction="column"
                sx={{ padding: 2 }}
                style={{ maxWidth: '850px', alignSelf: 'center' }}
            >
                    <AppBar />

                <Grid
                    item
                    xs
                    className="messages-section"
                    sx={{ overflowY: 'auto', flexGrow: 1 }}
                    ref={messagesSectionRef}
                >
                    {context.map(({ message, user_type, message_type, id }) =>
                        message_type === 'text' ? renderTextMessage(id, user_type, message) : null
                    )}
                </Grid>

                <Grid item style={{ textAlign: 'center' }}>
                    {showScrollButton && (
                        <Fab
                            color="primary"
                            size="small"
                            onClick={scrollToBottom}
                            style={{
                                position: 'absolute',
                                bottom: '20px',
                                zIndex: 1000,
                                backgroundColor: 'rgba(0, 0, 0, 0.87)',
                                top: '89.5%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                            }}
                        >
                            <ArrowDownwardIcon style={{ fontSize: 20 }} />
                        </Fab>
                    )}
                        <QuestionBar context={context} setContext={setContext} />

                </Grid>
            </Grid>
        </Grid>
    );
}
