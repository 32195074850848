import React from "react";
import MuiAppBar from '@mui/material/AppBar';
import { Grid, useMediaQuery, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './app-bar.scss';
import { useLocation, useNavigate } from "react-router-dom";
import AccountMenu from "../../../components/menu";
import Cisp1Logo from '../../../components/icons/cisp1-logo';

export default function AppBar() {
    const location = useLocation();  // Get the current location
    const navigate = useNavigate();  // Hook for navigation
    const isSmallScreen = useMediaQuery('(max-width:600px)');  // Define a breakpoint for small screens

    const handleGoBack = () => {
        navigate(-1);  // Go back to the previous page
    };

    return (
        <MuiAppBar position="relative" className="app-bar">
            <Grid
                container
                direction="row"
                alignItems="center"
                className="app-bar__container"
            >
                {/* Go Back Button - Start Aligned */}
                <Grid item xs={3} container justifyContent="flex-start">
                    {isSmallScreen && (
                        <IconButton onClick={handleGoBack} edge="start" color="black" aria-label="go back">
                            <ArrowBackIcon />
                        </IconButton>
                    )}
                </Grid>

                {/* Logo - Center Aligned */}
                <Grid item xs={6} gap={'20px'} container justifyContent="center">
                    <Grid item xs={5} container justifyContent="end" alignContent="center">
                        <Cisp1Logo
                            className="cisp1-logo"
                            style={{
                                width: isSmallScreen ? '' : '150px',
                                height: isSmallScreen ? '' : '41px'
                            }}
                        />
                    </Grid>

                    {/* Second Logo - Center Aligned */}
                    <Grid item xs={5} container justifyContent="start">
                        <img
                            src="vix-logo.jpg"
                            alt="Logo"
                            style={{ width: isSmallScreen ? '38px' : '53px' }}
                        />
                    </Grid>
                </Grid>
                {/* Account Menu - End Aligned */}
                <Grid item xs={3} container justifyContent="flex-end">
                    {location.pathname === '/' && (
                        <AccountMenu />
                    )}

                </Grid>
            </Grid>
        </MuiAppBar>
    );
}
