import React, { useState } from "react";
import './login-page.scss';
import { Button, CssBaseline, TextField, Box, Typography, Container, CircularProgress, Grid } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { createAlert } from '../../utils/alerts';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cisp1Logo from '../../components/icons/cisp1-logo'

const theme = createTheme();

function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({ email: false, password: false });
    const [messageAlert, setMessageAlert] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const validateEmail = (email) => {
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(String(email).toLowerCase());
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const emailError = !validateEmail(email);
        const passwordError = password === '';
        setLoading(true);
        
        if (!emailError && !passwordError) {

            try {
                const data = new FormData();
                data.append('username', email);
                data.append('password', password);

                const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/token`, data);

                const { access_token, refresh_token } = response.data;
                const expirationTime = new Date().getTime() + 86400 * 1000;

                localStorage.setItem('access_token', access_token);
                localStorage.setItem('refresh_token', refresh_token);
                localStorage.setItem('token_expiration', expirationTime);

                setMessageAlert(createAlert('success', 'Login successful. Redirecting to the system.'));
                setTimeout(() => {
                    navigate('/main')
                }, 1500);
            } catch (error) {
                let message = error.response?.status === 401
                    ? 'Login failed. Please check your credentials and try again.'
                    : 'Something went wrong. Please try again later.';

                setMessageAlert(createAlert('error', message));
                setLoading(false);
            }
        } else {
            setErrors({ email: emailError, password: passwordError });
            setLoading(false);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <div className="login-container">
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Grid
                        container
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            mb: 3,
                        }}
                        gap={3}
                    >
                        <Grid item xs={5} textAlign="end">
                            <Cisp1Logo className="cisp1-logo" style={{ width: '100px', height: '50px' }}/>
                        </Grid>
                        <Grid item xs={5}>
                            <img src="vix-logo.jpg" style={{ width: "55px" }} />
                        </Grid>
                        
                    </Grid>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            padding: '2rem',
                            border: '1px solid #ddd',
                            borderRadius: '6px',
                            backgroundColor: '#fff',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <Typography component="h1" variant="h5">
                            Login
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="E-mail"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                error={errors.email}
                                helperText={errors.email ? 'Invalid email' : ''}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                error={errors.password}
                                helperText={errors.password ? 'Password is required' : ''}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                disabled={loading}
                                endIcon={loading && <CircularProgress size={20} />}
                                className=""
                            >
                                {loading ? 'Logging in...' : 'Login'}
                            </Button>
                        </Box>
                        {messageAlert}

                    </Box>
                </Container>
            </div>

        </ThemeProvider>
    );
}

export default LoginPage;
