import React from 'react';
import ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import LoginPage from './pages/login';
import { AuthProvider } from './contexts/authContext';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import ChatPage from './pages/chat';
import MainPage from './pages/main';
import UsersPage from './pages/users';
import RegisterCompletePage from './pages/register';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <CssBaseline>
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path='/login' element={<PublicRoute restricted={true} element={<LoginPage />} />} />
          <Route path='/' element={<PrivateRoute element={<MainPage />} />} />
          <Route path='/chats' element={<PrivateRoute element={<ChatPage />} />} />
          <Route path='/users' element={<PrivateRoute element={<UsersPage />} />} />
          <Route path='/register' element={<PublicRoute element={<RegisterCompletePage />} />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  </CssBaseline>
);
